export interface SitePath {
  title: string;
  path: string;
  isExternal: boolean;
}

export const sitepaths: { [key: string]: SitePath } = {
  home: {
    title: "Home",
    path: "/",
    isExternal: false,
  },
  about: {
    title: "About",
    path: "/about",
    isExternal: false,
  },
  blog: {
    title: "Blog",
    path: "https://blog.jonathanlau.io",
    isExternal: true,
  },
  colophon: {
    title: "Colophon",
    path: "/humans.txt",
    isExternal: false,
  },
} as const;

export const secondarypaths = {
  licenses: {
    title: "Licenses",
    path: "/licenses",
  },
} as const;

export const socialpaths = {
  home: {
    title: "Linkedin",
    path: "https://www.linkedin.com/in/jonathanhlau",
    isExternal: true,
  },
  about: {
    title: "Github",
    path: "https://www.github.com/laujonat",
    isExternal: true,
  },
  experience: {
    title: "Email",
    path: "mailto:jon@jonathanlau.io",
    isExternal: false,
  },
  blog: {
    title: "Blog",
    path: "https://blog.jonathanlau.io",
    isExternal: true,
  },
} as const;
export const externalpaths = {
  resume: {
    title: "CV/Resume",
    path: "https://rxresu.me/laujonat/2024",
    isExternal: false,
  },
  speedlify: {
    title: "Speedlify",
    path: "https://speedlify.jonathanlau.io/jonathanlau.io/",
    isExternal: true,
  },
  rss: {
    title: "Sitemap",
    path: "https://www.jonathanlau.io/sitemap.xml",
    isExternal: false,
  },
  experience: {
    title: "RSS Feed",
    path: "https://blog.jonathanlau.io/feed/feed.xml",
    isExternal: false,
  },
} as const;

export const BLOG_URL = "https://blog.jonathanlau.io";
export const WCAG21_URL = "https://www.w3.org/TR/WCAG21/";
export const RESUME_URL =
  "https://jonathanlau.sfo3.cdn.digitaloceanspaces.com/jonathan_lau_fe_swe_resume.pdf";
export const externalLinkPath = {
  title: "blog.jonathanlau.io", // improved title
  path: BLOG_URL,
  isExternal: true,
};
export const cvLinkPath = {
  title: "Resume",
  path: RESUME_URL,
  isExternal: true,
};
export const a11yLinkPath = {
  title: "Web Content Accessibility Guidelines (WCAG) 2.1",
  path: WCAG21_URL,
  isExternal: true,
};

export const avatarUrls = [
  {
    url: "https://res.cloudinary.com/hakkei-co/image/upload/q_auto/v1720627091/schema/avatar_48.webp",
    size: 48,
  },
  {
    url: "https://res.cloudinary.com/hakkei-co/image/upload/q_auto/v1720627091/schema/avatar_128.webp",
    size: 128,
  },
  {
    url: "https://res.cloudinary.com/hakkei-co/image/upload/q_auto/v1720627091/schema/avatar_256.webp",
    size: 256,
  },
  {
    url: "https://res.cloudinary.com/hakkei-co/image/upload/q_auto/v1720627091/schema/avatar_512.webp",
    size: 512,
  },
];
export const mediumFeatures = [
  {
    title: "Async Events in Sequence with React",
    url: "https://medium.com/gitconnected/async-events-in-sequence-with-react-aefd6749d37b",
    likes: 429,
    publisher: "Level Up Coding",
    date: 2020,
  },
  {
    title: "React Refs for Function Components",
    url: "https://medium.com/gitconnected/react-refs-for-function-components-44f1a5a2332a",
    likes: 590,
    publisher: "Level Up Coding",
    date: 2020,
  },
  {
    title: "Synchronizing Shell Environment in Bash ",
    url: "https://medium.com/swlh/how-i-use-a-bash-script-to-synchronize-shell-environments-349ff45d44e5",
    likes: 406,
    publisher: "The Startup",
    date: 2019,
  },
  {
    title: "Data Visualization for other Humans",
    url: "https://medium.com/towards-data-science/effective-data-visualization-for-other-humans-695e54a5dc71",
    likes: 258,
    publisher: "Towards Data Science",
    date: 2019,
  },
  {
    title: "Styled Components & Tachyons",
    url: "https://medium.com/towards-data-science/effective-data-visualization-for-other-humans-695e54a5dc71",
    likes: 162,
    publisher: "The Startup",
    date: 2019,
  },
];
export const popularPosts = [
  {
    title: "Displaying Line Numbers In 11ty Code Blocks",
    url: "https://blog.jonathanlau.io/posts/2024-06-10-displaying-line-numbers-in-11ty-code-blocks",
    date: 2024,
    highlight: "in 11tybundle.dev digest",
  },
  {
    title: "Create an Interactive TOC from Markdown Generated Content",
    url: "https://blog.jonathanlau.io/posts/create-interactive-toc-in-markdown-generated-content",
    date: 2024,
    highlight: "in 11tybundle.dev digest",
  },
];
